import * as React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { AiOutlineInstagram } from "@react-icons/all-files/ai/AiOutlineInstagram";
import { AiFillGithub } from "@react-icons/all-files/ai/AiFillGithub";
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail";

import { Page } from "../components/Page";
import { ContentfulResponse } from "../utils/queryTypes";

interface Response {
  fluid: {
    src: string;
    srcSet: string;
    srcSetWebp: string;
    srcWebp: string;
    aspectRatio: number;
    sizes: string;
  };
}

const Hoverable = (props: { link: string; children: React.ReactNode }) => (
  <motion.div whileHover={{ scale: 1.5, transition: { duration: 0.1 } }}>
    <a href={props.link}>{props.children}</a>
  </motion.div>
);

export default () => {
  const data = useStaticQuery<
    ContentfulResponse<"contentfulAsset", Response>
  >(graphql`
    query {
      contentfulAsset(contentful_id: { eq: "68QLZCxQ4UyhGDqqlUalFh" }) {
        fluid(maxWidth: 3000, quality: 65) {
          src
          srcSet
          srcSetWebp
          srcWebp
          aspectRatio
          sizes
        }
      }
    }
  `);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const centered: React.CSSProperties = {
    display: "flex",
    justifyContent: "center"
  };

  return (
    <>
      <Page>
        <div style={centered}>
          <div style={{ width: isTabletOrMobile ? "90%" : "40%" }}>
            <Img
              fluid={data.contentfulAsset.fluid}
              style={{ boxShadow: "2px 2px 10px" }}
            />
            <div style={{ ...centered, textAlign: "center" }}>
              <h6>software engineer by day, fermentation amateur by night</h6>
            </div>
            <div style={centered}>
              <div
                style={{
                  width: "50%",
                  fontSize: "36px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px"
                }}
              >
                <Hoverable link="https://www.instagram.com/harrystevenson1">
                  <AiOutlineInstagram style={{ color: "#E1306C" }} />
                </Hoverable>
                <Hoverable link="https://github.com/harrystevenson">
                  <AiFillGithub style={{ color: "rgb(0, 0, 0)" }} />
                </Hoverable>
                <Hoverable link="mailto:hs@harry.st">
                  <AiOutlineMail style={{ color: "rgb(114, 41, 183)" }} />
                </Hoverable>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};
